import React, { useEffect } from 'react';
import '../../App.css';

function CookiesPolicy() {
  useEffect(() => {
    // Add the background color class to the body
    document.body.classList.add('body-background');

    // Remove the background color class from the body when the component unmounts
    return () => {
      document.body.classList.remove('body-background');
    };
  }, []);

  return (
    <div className="app-container">
      <h1 className="app-title">Política de Cookies</h1>

      <p className="app-paragraph">
        Usamos cookies no nosso site para melhorar o desempenho e a sua experiência como utilizador. Esta página explica como o fazemos.
      </p>

      <h2 className="app-subtitle">O que são cookies</h2>
      <p className="app-paragraph">
        Os cookies são pequenos ficheiros de texto que um site, ao ser visitado pelo utilizador, coloca no seu computador ou no seu dispositivo móvel através do navegador de internet (browser). A colocação de cookies ajudará o site a reconhecer o seu dispositivo na próxima vez que o utilizador o visitar.
      </p>
      <p className="app-paragraph">
        Usamos o termo cookies nesta política para referir todos os ficheiros que recolhem informações desta forma.
      </p>
      <p className="app-paragraph">
        Os cookies utilizados não recolhem informação que identifica o utilizador. Os cookies recolhem informações genéricas, designadamente a forma como os utilizadores chegam e utilizam os sites ou a zona do país/países através do qual acedem ao site, etc.
      </p>
      <p className="app-paragraph">
        Os cookies retêm apenas informação relacionada com as suas preferências.
      </p>
      <p className="app-paragraph">
        A qualquer momento o utilizador pode, através do seu navegador de internet (browser) decidir ser notificado sobre a receção de cookies, bem como bloquear a respetiva entrada no seu sistema.
      </p>
      <p className="app-paragraph">
        A recusa de uso de cookies no site, pode resultar na impossibilidade de ter acesso a algumas das suas áreas ou de receber informação personalizada.
      </p>

      <h2 className="app-subtitle">Para que servem os cookies</h2>
      <p className="app-paragraph">
        Os cookies são usados para ajudar a determinar a utilidade, interesse e o número de utilizações dos sites, permitindo uma navegação mais rápida e eficiente e eliminando a necessidade de introduzir repetidamente as mesmas informações.
      </p>

      <h2 className="app-subtitle">Que tipo de cookies utilizamos</h2>
      <p className="app-paragraph">
        Os nossos cookies têm diferentes funções:
      </p>
      <ul className="app-list">
        <li className="app-list-item">
          <strong>Cookies essenciais</strong> - Alguns cookies são essenciais para aceder a áreas específicas do nosso site. Permitem a navegação no site e a utilização das suas aplicações, tal como aceder a áreas seguras do site através de login. Sem estes cookies, os serviços que o exijam não podem ser prestados.
        </li>
        <li className="app-list-item">
          <strong>Cookies analíticos</strong> - Utilizamos estes cookies para analisar a forma como os utilizadores usam o site e monitorar a performance deste. Isto permite-nos fornecer uma experiência de alta qualidade ao personalizar a nossa oferta e rapidamente identificar e corrigir quaisquer problemas que surjam. Por exemplo, usamos cookies de desempenho para saber quais as páginas mais populares, qual o método de ligação entre páginas que é mais eficaz, ou para determinar a razão de algumas páginas estarem a receber mensagens de erro. Baseado na utilização do site, podemos também utilizar estes cookies para destacar artigos ou serviços do site que pensamos ser do interesse dos utilizadores. Estes cookies são utilizados apenas para efeitos de criação e análise estatística, sem nunca recolher informação de caráter pessoal.
        </li>
        <li className="app-list-item">
          <strong>Cookies de funcionalidade</strong> - Utilizamos cookies de funcionalidade para nos permitir relembrar as preferências do utilizador. Por exemplo, os cookies evitam digitar o nome do utilizador cada vez que este acede ao site. Também usamos cookies de funcionalidade para fornecer serviços avançados ao utilizador, como por exemplo efetuar comentários a um artigo. Em resumo, os cookies de funcionalidade guardam as preferências do utilizador relativamente à utilização do site, de forma que não seja necessário voltar a configurar o site cada vez que o visita.
        </li>
        <li className="app-list-item">
          <strong>Cookies de terceiros</strong> - Servem para medir o sucesso de aplicações e a eficácia da publicidade de terceiros.
        </li>
        <li className="app-list-item">
          <strong>Cookies de publicidade</strong> - Servem para direcionar a publicidade em função dos interesses de cada utilizador, permitindo limitar o número de vezes do visionamento do anúncio. Estes cookies ajudam a medir a eficácia da publicidade. No entanto, não identificam o utilizador.
        </li>
      </ul>

      <h2 className="app-subtitle">Os cookies utilizados podem ser:</h2>
      <ul className="app-list">
        <li className="app-list-item">
          <strong>Cookies permanentes</strong> - Ficam armazenados ao nível do navegador de internet (browser) nos seus dispositivos de acesso (pc, mobile e tablet) e são utilizados sempre que o utilizador faz uma nova visita ao site. Geralmente são utilizados para direcionar a navegação de acordo com os interesses do utilizador, permitindo-nos prestar um serviço mais personalizado.
        </li>
        <li className="app-list-item">
          <strong>Cookies de sessão</strong> - São temporários, permanecem nos cookies do seu navegador de internet (browser) até sair do site. A informação obtida permite identificar problemas e fornecer uma melhor experiência de navegação.
        </li>
      </ul>

      <h2 className="app-subtitle">O uso de cookies pode ser bloqueado</h2>
      <p className="app-paragraph">
        Como explicámos os cookies ajudam-no a tirar o máximo proveito dos nossos sites. Depois de autorizar o uso de cookies, o utilizador pode sempre desativar parte ou a totalidade dos nossos cookies. Para o efeito, o utilizador deve seguir as instruções na nossa página "Como gerir cookies".
      </p>
      <p className="app-paragraph">
        Recordamos que ao desativar os cookies, partes do nosso site podem não funcionar corretamente.
      </p>

      <h2 className="app-subtitle">Uso de cookies na abertura das nossas newsletters e emails marketing</h2>
      <p className="app-paragraph">
        As nossas newsletters e emails marketing podem, para fins estatísticos, conter um único "pixel" que nos permite saber se são abertas e verificar os cliques através de links ou anúncios dentro da newsletter e/ou emails marketing. O utilizador tem sempre a possibilidade de desativar o envio da newsletter e/ou email marketing.
      </p>

      <h2 className="app-subtitle">Mais informação sobre cookies</h2>
      <p className="app-paragraph">
        Pode obter mais informações sobre cookies no seguinte link: <a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a>
      </p>
    </div>
  );
}

export default CookiesPolicy;