import React from 'react';
import '../css/SponsorsPage.css';

function SponsorsPage() {
  const sponsors = [
    {
      category: 'Organização',
      companies: [
          { name: 'ULS', logo: '/img/partner-logo/logo-4.png' },
        { name: 'CMC', logo: '/img/partner-logo/logo-2.png' },
      ],
    },
    {
      category: 'Institucionais',
      companies: [
        { name: 'Company C', logo: '/img/partner-logo/logo-1.png' },
        { name: 'Company D', logo: '/img/partner-logo/logo-5.png' },
        { name: 'Company D', logo: '/img/partner-logo/logo-3.png' },
      ],
    },
    {
      category: 'Expositores',
      companies: [
     
      ],
    },
  ];

  return (
    <section className="sponsors-page-section">
      <div className="container">
        <div className="sponsors-page-section-title">
          <h2>Parceiras</h2>
        </div>
        {sponsors.map((sponsor, index) => (
          <div key={index} className="sponsors-page-category">
            <h3>{sponsor.category}</h3>
            <div className="sponsors-page-logos">
              {sponsor.companies.map((company, idx) => (
                <div key={idx} className="sponsors-page-logo">
                  <img src={company.logo} alt={company.name} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default SponsorsPage;