import React, { useState, useEffect } from 'react';
import '../css/Cookies.css';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    } else {
      setPreferences(JSON.parse(consent));
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(preferences));
    setIsVisible(false);
  };

  const handleSettingsSave = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(preferences));
    setIsSettingsVisible(false);
    setIsVisible(false);
  };

  const handlePreferenceChange = (e) => {
    const { name, checked } = e.target;
    setPreferences((prev) => ({ ...prev, [name]: checked }));
  };

  if (!isVisible && !isSettingsVisible) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <div className="cookie-consent-content">
        <p>
          Utilizamos cookies para garantir que obtém a melhor experiência no nosso site. Ao continuar a usar este site, você consente com a nossa utilização de cookies.
        </p>
        <button className="accept-button" onClick={handleAccept}>
          Aceitar
        </button>
        <button className="settings-button" onClick={() => setIsSettingsVisible(true)}>
          Configurações de Cookies
        </button>
      </div>

      {isSettingsVisible && (
        <div className="cookie-settings-modal">
          <h2>Configurações de Cookies</h2>
          <label>
            <input
              type="checkbox"
              name="necessary"
              checked={preferences.necessary}
              disabled
            />
            Necessário
          </label>
          <label>
            <input
              type="checkbox"
              name="analytics"
              checked={preferences.analytics}
              onChange={handlePreferenceChange}
            />
            Analítico
          </label>
          <label>
            <input
              type="checkbox"
              name="marketing"
              checked={preferences.marketing}
              onChange={handlePreferenceChange}
            />
            Marketing
          </label>
          <button className="save-button" onClick={handleSettingsSave}>
            Salvar Configurações
          </button>
        </div>
      )}
    </div>
  );
};

export default CookieConsent;