// src/components/pages/Home.js
import React from "react";
import RollerText from "../js/RollerText";
import HeroSection from "../js/HeroSection";
import About from "../js/About";
import Timeline from "../js/Timeline";
import Speakers from "../js/Speakers";
import Pricing from "../js/Pricing";

function Home() {
  const rollerTexts = [
    { content: "X", font: "Inter-Regular", color: "#2e3191" },
    { content: "04.05 ABR 2025", font: "Inter-Regular", color: "#2e3191" },
    { content: "●", font: "Inter-Regular", color: "#2e3191" },
    {
      content: " Coimbra - Convento São Francisco",
      font: "SourceSerif4-SemiBoldItalic",
      color: "#2e3191",
    },
    { content: "■ ", font: "Inter-Regular", color: "#2e3191" },
    {
      content: "INSCRIÇÕES 01 OUT 2024",
      font: "Inter-Regular",
      color: "#2e3191",
    },
  ];

  return (
    <div className="Home">
      <div className="roller-text-wrapper">
        <RollerText texts={rollerTexts} fontSize="2rem" />
      </div>
      <HeroSection />
      <About />
      <Timeline />
      <Speakers />
      <Pricing />
    </div>
  );
}

export default Home;