import React, { useEffect } from "react";
import { Link } from 'react-router-dom';
import "../css/Header.css";

function Header() {
  useEffect(() => {
    const hamburger = document.querySelector(".hamburger-menu");
    const navMenu = document.querySelector(".mainmenu");

    const toggleMenu = () => {
      navMenu.classList.toggle("active");
    };

    hamburger.addEventListener("click", toggleMenu);

    return () => {
      hamburger.removeEventListener("click", toggleMenu);
    };
  }, []);

  return (
    <header className="header-section">
      <div className="logo">
        <Link to="/">
          <img src={`${process.env.PUBLIC_URL}/img/logo.png`} alt="Event Logo" />
        </Link>
      </div>
      <div className="nav-menu">
        <nav className="mainmenu">
          <ul>
            <li className="active">
              <Link to="/">Início</Link>
            </li>
            <li>
              <Link to="/exhibition">Exhibition</Link>
            </li>
            <li>
              <Link to="/schedule">Programa</Link>
            </li>
            <li>
              <Link to="/sponsors">Parcerias</Link>
            </li>
            <li>
              <Link to="/hotels">Hotel/Rest</Link>
            </li>
            <li>
              <Link to="/contact">Contactos</Link>
            </li>
          </ul>
          <Link to="https://form.jotform.com/242143179037353">
          <button className="primary-btn top-btn">
            Inscrição
          </button>
          </Link>
        </nav>
      </div>
      <div className="hamburger-menu">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
}

export default Header;