import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import '../css/Exhibition.css';
import { EffectFade, Pagination, Autoplay } from 'swiper/modules';

function Exhibition() {
  const slides = [
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-1.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-2.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-3.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-4.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-5.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-6.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-7.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-8.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-9.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-10.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-11.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-12.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-13.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-14.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-15.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-16.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-17.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-18.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-19.jpg`,
    },
    {
      image: `${process.env.PUBLIC_URL}/img/exhibition/exhibition-20.jpg`,
    },
  ];

  return (
    <div className="exhibition-page">
      <div className="exhibition-container">
        <h1 className="exhibition-title">MEDICAL RADIOLOGY EXHIBITION</h1>
        <Swiper
          effect={'fade'}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={1}
          spaceBetween={30}
          fadeEffect={{ crossFade: true }}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[EffectFade, Pagination, Autoplay]}
          className="exhibition-swiper-container"
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>
              <div className="exhibition-swiper-slide-content">
                <img src={slide.image} alt={`slide_image_${index}`} />
              </div>
            </SwiperSlide>
          ))}
          <div className="exhibition-swiper-pagination"></div>
        </Swiper>
        <div className="exhibition-content">
          <p>
            Desde a primeira hora que foi importante, para a comissão organizadora, que o Congresso Internacional de Radiologia de Coimbra-CIRC se cruzasse com a cidade e estrategicamente se assuma como um evento de interesse para Coimbra (CMC) e o turismo do conhecimento (CHUC).
          </p>
          <p>
            Formámos uma associação sem fins lucrativos (AHD-ASSOCIAÇÃO HEMISFÉRIO DISCIPLINADO), e com co-organização da Câmara Municipal de Coimbra e do Centro Hospitalar e Universitário de Coimbra- CHUC, E.P.E. propomos, no tempo do congresso, outro evento que concretize a ideia de envolver a Radiologia com a cidade, obtendo também apoio do Turismo do Centro de Portugal.
          </p>
          <p>
            A MEDICAL RADIOLOGY EXHIBITON constitui-se como uma MOSTRA de produtos dirigidos, através de stands comerciais, que convida os diferentes players da área da Radiologia a estarem presentes. Empresas de equipamentos, material de intervenção, empresas de contrastes, decoração de interiores e hotelaria hospitalares, outros consumíveis, serviços de consultadoria, transformação digital, e todos os atores indispensáveis aos serviços de radiologia no século XXI dispõem, nesta primeira MOSTRA, de 17 stands para exibirem o que de melhor oferecem.
          </p>
          <p>
            Os dois eventos são indissociáveis. O CIRC e o Medical Radiology Exhibition trazem novidades, tanto no financiamento como no acesso.
          </p>
          <p>
            Este formato está pensado do modo seguinte:
          </p>
          <ol>
            <li>Stands, localizados numa zona preparada para os parceiros receberem os seus convidados, os convidados da organização, para além dos 400 congressistas.</li>
            <li>Acesso independente do Congresso.</li>
            <li>Liberdade para parceiros convidarem os seus próprios clientes ou futuros clientes livremente (nacionais e internacionais) para visitarem a Exposição durante os dois dias das 9.00h às 18.00h.</li>
            <li>Têm igualmente acesso a um espaço preparado com projetor de cinema/vídeo onde podem fazer diferentes abordagens exploratórias dos produtos que desenvolvem.</li>
          </ol>
          <p>
            A nossa expectativa é que este formato de dois dias, permita construir um congresso cientificamente relevante, ao mesmo tempo, oferecer dois dias em que os atores, públicos e privados, do mundo da radiologia façam os seus negócios como em qualquer feira e os parceiros mostrem as suas novidades numa área que está em constante revolução.
          </p>
          <p>
            Quais as vantagens desta proposta?
          </p>
          <p>
            Este é um formato win-win. Um jogo win-win é um jogo que é projetado de forma que todos os participantes possam lucrar com isso de uma forma ou de outra (c.f Leonard Boff ou a Teoria dos Jogos).
          </p>
          <p>
            O conceito win-win vem de winner, e é nessa perspetiva que ele deve ser pensado. A perspetiva onde todos vencem, ganham sem que ninguém abra mão de nada.
          </p>
          <p>
            Para nós é importante contribuirmos para a mudança de paradigma, e pautar a nossa relação com os parceiros com uma plataforma para os seus negócios e crescimento, criando relacionamentos sérios com a indústria e outros stakeholders.
          </p>
          <p>
            Conscientes que não é fácil, certos de que é uma arte, um processo engenhoso de empatia, de comprometimento e de ética. Levará tempo, sabemos, esta é uma proposta, apesar de clara, implica investimento de tempo, novas linguagens, honestidade intelectual, fidelização, satisfação mútua.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Exhibition;