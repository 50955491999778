import React, { useEffect } from 'react';
import '../../App.css';

function PrivacyPolicy() {
  useEffect(() => {
    // Add the background color class to the body
    document.body.classList.add('body-background');

    // Remove the background color class from the body when the component unmounts
    return () => {
      document.body.classList.remove('body-background');
    };
  }, []);

  return (
    <div className="app-container">
      <h1 className="app-title">Política de Privacidade</h1>

      <h2 className="app-subtitle">1. Indentificação do responsável pelo tratamento</h2>
      <p className="app-paragraph">
        Nome: Congresso Internacional de Radiologia de Coimbra-CIRC<br />
        Sede: Rua João Moreno, Lote 1, Loja B 3030-776, Coimbra.
      </p>
      <p className="app-paragraph">
        <strong>Contacto Encarregado da Proteção de Dados:</strong><br />
        Carla Maria Solano Máximo da Rocha<br />
        Email: ahd-geral@outlook.com
      </p>

      <h2 className="app-subtitle">2. Informação e consentimento</h2>
      <p className="app-paragraph">
        A Lei da Proteção de Dados Pessoais (em diante “LPDP”) e o Regulamento Geral de Proteção de Dados (Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016, em diante “RGPD”) asseguram a proteção das pessoas singulares no que diz respeito ao tratamento de dados pessoais e à livre circulação desses dados.
      </p>
      <p className="app-paragraph">
        Nos termos legais são considerados "dados pessoais" qualquer informação, de qualquer natureza e independentemente do respetivo suporte, incluindo som e imagem, relativa a uma pessoa singular identificada ou identificável, pelo que a proteção não abrange os dados de pessoas coletivas.
      </p>
      <p className="app-paragraph">
        Mediante a aceitação da presente Política de Privacidade o utilizador presta o seu consentimento informado, expresso, livre e inequívoco para que os dados pessoais fornecidos através do site <a href="https://www.circ-cb.com/" target="_blank" rel="noopener noreferrer">https://www.circ-cb.com/</a> sejam incluídos num ficheiro da responsabilidade do CIRC, cujo tratamento nos termos da LPDP e do RGPD cumpre as medidas de segurança técnicas e organizativas adequadas.
      </p>

      {/* Add more sections in a similar format */}
      <h2 className="app-subtitle">3. Finalidades dos dados pessoais</h2>
      <p className="app-paragraph">
        Os dados pessoais que tratamos através desta página serão unicamente utilizados para as seguintes finalidades:
      </p>
      <ul className="app-list">
        <li className="app-list-item">Processamento de encomendas;</li>
        <li className="app-list-item">Comunicação com clientes/participantes e esclarecimento de dúvidas;</li>
        <li className="app-list-item">Processamento de pedidos de informação;</li>
        <li className="app-list-item">Processamento de reclamações;</li>
        <li className="app-list-item">Atividades de análise estatística;</li>
        <li className="app-list-item">Verificar, manter e desenvolver sistemas e análises estatísticas;</li>
        <li className="app-list-item">Comunicações de marketing direto (caso tenha consentido no tratamento dos seus dados pessoais para esta finalidade);</li>
        <li className="app-list-item">Prevenção e combate à fraude;</li>
        <li className="app-list-item">Solicitação de comentários a produtos ou serviços adquiridos;</li>
        <li className="app-list-item">Realização de inquéritos de satisfação;</li>
        <li className="app-list-item">Gestão e participação em eventos, passatempos e campanhas.</li>
      </ul>

      <h2 className="app-subtitle">4. Cessão de dados pessoais</h2>
      <p className="app-paragraph">
        Por forma a poder cumprir com o objeto do presente website, o CIRC irá ceder os seus dados pessoais a outras entidades, que os irão tratar, para as seguintes finalidades:
      </p>
      <ul className="app-list">
        <li className="app-list-item">Atividades de gestão e processamento de pagamentos;</li>
        <li className="app-list-item">Prestação dos serviços contratados;</li>
        <li className="app-list-item">Gestão e participação em eventos, passatempos e campanhas.</li>
      </ul>

      <h2 className="app-subtitle">5. Medidas de segurança</h2>
      <p className="app-paragraph">
        O CIRC declara que implementou e continuará a implementar as medidas de segurança de natureza técnica e organizativa necessárias para garantir a segurança dos dados de carácter pessoal que lhe sejam fornecidos visando evitar a sua alteração, perda, tratamento e/ou acesso não autorizado, tendo em conta o estado atual da tecnologia, a natureza dos dados armazenados e os riscos a que estão expostos.
      </p>

      {/* Continue adding other sections in a similar way */}
    </div>
  );
}

export default PrivacyPolicy;
