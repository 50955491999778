import React from 'react';
import '../css/Speakers.css';

const speakers = [
  { name: 'Samuel Oliveira', image: 'img/speakers/speaker-1.jpg' },
  { name: 'Barbara Nugent',  image: 'img/speakers/speaker-2.jpg' },
  { name: 'Bac Nguyen', image: 'img/speakers/speaker-3.jpg' },
  { name: 'Luís Campos', image: 'img/speakers/speaker-4.jpg'},
  { name: 'Ana Luísa Soares', image: 'img/speakers/speaker-5.jpg'},
  { name: 'Tânia Gaspar', image: 'img/speakers/speaker-6.jpg'},
  { name: 'Zita Espírito Santo', image: 'img/speakers/speaker-7.jpg'},
];

function Speakers() {
  return (
    <section className="speaker-section spad">
      <div className="container">
        <div className="section-title">
          <h2>Palestrantes</h2>
          <p>Conheça os comunicadores do nosso evento</p>
        </div>
        <div className="row">
          {speakers.map((speaker, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12">
              <div className="speaker-item">
                <div className="si-pic">
                  <img src={speaker.image} alt={speaker.name} />
                  <div className="si-text">
                    <h4>{speaker.name}</h4>
                  </div>
                  <div className="si-social">
                    <a href="/"><i className="fa fa-linkedin"></i></a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Speakers;