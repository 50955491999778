import React from 'react';
import '../css/Timeline.css';

const Timeline = () => {
  const events = [
    {
      date: "Julho 1",
      description: "Submissão de resumos para apresentações orais e posters",
      hasButton: true, // Adicionando flag para o botão
      buttonText: "Submeter Trabalhos",
      buttonLink: "https://form.jotform.com/241273222563349"
    },
    {
      date: "Outubro 1",
      description: "Abertura das Inscrições"
    },
    {
      date: "Dezembro 31",
      description: "Prazo final para submissão de resumos"
    },
    {
      date: "Abril 3, 4 e 5 - 2025",
      description: "CIRC 2025"
    }
  ];

  return (
    <section className="timeline-container">
      <h2 className="timeline-title">Cronologia do Evento</h2>
      <ul className="timeline">
        {events.map((event, index) => (
          <li key={index} className="timeline-item">
            <div className="timeline-dot"></div>
            <div className="timeline-content">
              <span className="timeline-date">{event.date}</span>
              <p>{event.description}</p>
              {event.hasButton && (
                <a 
                  href={event.buttonLink} 
                  className="timeline-button" 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {event.buttonText}
                </a>
              )}
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Timeline;
