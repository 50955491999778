import React, { useState, useEffect, useRef } from 'react';
import '../css/RollerText.css';
import PropTypes from 'prop-types';

const RollerText = ({ texts, speed = 20, fontSize = 'xx-large' }) => {
  const [offset, setOffset] = useState(window.innerWidth);
  const textRef = useRef(null);

  useEffect(() => {
    const textElement = textRef.current;
    if (!textElement) return;

    const textWidth = textElement.scrollWidth;
    const moveText = () => {
      setOffset((prevOffset) => {
        if (prevOffset <= -textWidth) {
          return window.innerWidth;
        }
        return prevOffset - 1;
      });
    };

    const intervalId = setInterval(moveText, speed);

    return () => clearInterval(intervalId);
  }, [speed]);

  return (
    <div className="roller-container">
      <div
        className="roller-text"
        style={{ transform: `translateX(${offset}px)`, fontSize }}
        ref={textRef}
      >
        {texts.map((text, index) => (
          <span
            key={index}
            style={{
              fontFamily: text.font,
              color: text.color,
              marginRight: '20px',
            }}
          >
            {text.content}
          </span>
        ))}
      </div>
    </div>
  );
};

RollerText.propTypes = {
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      font: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  speed: PropTypes.number,
  fontSize: PropTypes.string,
};

export default RollerText;