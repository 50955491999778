import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import '../css/HeroSection.css';
import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

function HeroSection() {
  const navigate = useNavigate();

  const slides = [
    {
      image: '/img/related-post/related-post-1.jpg',
      title: 'Medical Exhibition',
      link: '/exhibition'
    },
    {
      image: '/img/related-post/related-post-2.jpg',
      title: 'Submissão de Trabalhos',
      link: 'https://form.jotform.com/241273222563349'
    },
    {
      image: '/img/related-post/related-post-3.jpg',
      title: 'O Conceito',
      link: '/exhibition'
    },
    {
      image: '/img/related-post/related-post-4.jpg',
      title: 'Programa Oficial 2025',
      link: '/schedule'
    },
  ];

  const handleSlideClick = (link) => {
    if (link.startsWith('http')) {
      // Se o link é um URL absoluto, abrir em uma nova janela
      window.open(link, '_blank');
    } else {
      // Se é um link interno, utilizar o navigate
      navigate(link);
    }
  };

  return (
    <section className="collection">
      <div className="swiper mySwiper">
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 150,
            modifier: 2.5,
            slideShadows: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          className="hero-swiper-container"
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="content" onClick={() => handleSlideClick(slide.link)}>
              <div className="slide-background" style={{ backgroundImage: `url(${slide.image})` }}>
                <h3 className="slide-title">{slide.title}</h3>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}

export default HeroSection;
