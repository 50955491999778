import React from 'react';
import '../css/Pricing.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faStar } from '@fortawesome/free-solid-svg-icons';

const pricingData = [
  { category: 'Congressistas ULS Coimbra', price: '75€', priceWithDinner: '115€' },
  { category: 'Congressistas Externos', price: '160€', priceWithDinner: '195€' },
  { category: 'Estudantes IMR', price: '120€', priceWithDinner: '155€' },
];

function Pricing() {
  return (
    <section className="pricing-section">
      <div className="container">
        <div className="section-title">
          <h2>Taxas de Inscrição</h2>
          <p>Escolha o seu plano de inscrição</p>
        </div>
        
        {/* Pricing before 1st February */}
        <div className="pricing-group">
          <h3 className="group-title">Preços até 31 de Janeiro de 2025</h3>
          <div className="pricing-cards">
            <div className="pricing-card">
              <div className="card-header">Congressistas ULS Coimbra</div>
              <div className="card-price">€40</div>
              <div className="card-description">
                <p>Congresso</p>
                <p><FontAwesomeIcon icon={faUtensils} /> Congresso + Jantar: €75</p>
              </div>
              <a href="https://form.jotform.com/242143179037353" className="card-btn">Avançar</a>
            </div>

            <div className="pricing-card featured-card">
              <div className="card-header">
                Congressistas Externos
              {/*   <p className="popular-badge"><FontAwesomeIcon icon={faStar} /> Popular </p>  */}
              </div>
              <div className="card-price">€80</div>
              <div className="card-description">
                <p>Congresso</p>
                <p><FontAwesomeIcon icon={faUtensils} /> Congresso + Jantar: €115</p>
              </div>
              <a href="https://form.jotform.com/242143179037353" className="card-btn">Avançar</a>
            </div>

            <div className="pricing-card">
              <div className="card-header">Estudantes IMR</div>
              <div className="card-price">€60</div>
              <div className="card-description">
                <p>Congresso</p>
                <p><FontAwesomeIcon icon={faUtensils} /> Congresso + Jantar: €95</p>
              </div>
              <a href="https://form.jotform.com/242143179037353" className="card-btn">Avançar</a>
            </div>

            <div className="pricing-card">
              <div className="card-header">Congresso Virtual</div>
              <div className="card-price">€60</div>
              <div className="card-description">
                <p>Congresso</p>
                <small>Nota: A inscrição virtual encerra em 31 de Março de 2025.</small>
              </div>
              <a href="https://form.jotform.com/242143179037353" className="card-btn">Avançar</a>
            </div>

            <div className="pricing-card">
              <div className="card-header">Pacote Extra de Jantar</div>
              <div className="card-price">€35</div>
              <div className="card-description">
                <FontAwesomeIcon icon={faUtensils} />
              </div>
              <a href="https://form.jotform.com/242143179037353" className="card-btn">Avançar</a>
            </div>
          </div>
        </div>

        {/* Pricing after 1st February */}
        <div className="pricing-group">
          <h3 className="group-title">Preços a partir de 1 de fevereiro de 2025</h3>
          <div className="pricing-cards">
            {pricingData.map((item, index) => (
              <div key={index} className="pricing-card">
                <div className="card-header">{item.category}</div>
                <div className="card-price">{item.price}</div>
                <div className="card-description">
                  <FontAwesomeIcon icon={faUtensils} /> Com jantar: {item.priceWithDinner}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pricing;
