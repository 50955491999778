import React from 'react';
import ContactForm from './ContactForm';
import '../css/Footer.css';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-columns">
          <div className="footer-column small-column">
            <h4>Sobre</h4>
            <ul>
              <li><a href="/">Associação</a></li>
              <li><a href="/">CIRC2023</a></li>
              <li><a href="/privacy-policy">Política de Privacidade</a></li>
              <li><a href="/terms-of-use">Termos de Utilização</a></li>
              <li><a href="/cookies-policy">Política de Cookies</a></li>
            </ul>
          </div>
          <div className="footer-column small-column">
            <h4>Comissões</h4>
            <ul>
              <li><a href="/">Comissão Organizadora</a></li>
              <li><a href="/">Comissão Científica</a></li>
              <li><a href="/">Comissões Técnicas</a></li>
            </ul>
          </div>

          <div className="footer-column large-column">
            <h4>Contacte-nos</h4>
            <ContactForm />
          </div>
        </div>
        
        {/* Social Media Icons */}
        <div className="footer-social">
          <a href="https://www.facebook.com/circ.chuc/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://www.instagram.com/circ.chuc/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/circ-chuc/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>

        <div className="footer-bottom">
          <p>
            Copyright &copy; {currentYear} Direitos Reservados | Hemisfério Disciplinado 2024
          </p>
        </div>
      </div>
      <div className="back-to-top">
        <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} aria-label="Back to Top">
          <i className="fas fa-arrow-up"></i>
        </button>
      </div>
    </footer>
  );
}

export default Footer;
