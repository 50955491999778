import React, { useEffect } from 'react';
import '../../App.css';

function TermsOfUse() {
  useEffect(() => {
    // Add the background color class to the body
    document.body.classList.add('body-background');

    // Remove the background color class from the body when the component unmounts
    return () => {
      document.body.classList.remove('body-background');
    };
  }, []);
  return (
    <div className="app-container">
      <h1 className="app-title">Termos de Utilização</h1>

      <p className="app-paragraph">
        Bem-vindo ao site oficial "circ-cb.com". Se continuar a navegar e a usar este website, concorda em cumprir e ficar vinculado aos seguintes termos e condições de utilização, que, juntamente com a nossa Política de Privacidade, regem a relação entre [Nome do Seu Website] e você em relação a este website. Se discordar de alguma parte destes termos e condições, por favor, não utilize o nosso website.
      </p>

      <h2 className="app-subtitle">A utilização deste website está sujeita aos seguintes termos de utilização:</h2>

      <ol className="app-list">
        <li className="app-list-item">
          <strong>Aviso de Conteúdo:</strong> O conteúdo das páginas deste website destina-se apenas a informação geral e uso. Está sujeito a alterações sem aviso prévio.
        </li>

        <li className="app-list-item">
          <strong>Utilização de Cookies:</strong> Este website utiliza cookies para monitorizar as preferências de navegação. Se permitir a utilização de cookies, informações pessoais podem ser armazenadas por nós para uso por terceiros.
        </li>

        <li className="app-list-item">
          <strong>Privacidade:</strong> A sua utilização deste website é regida pela nossa Política de Privacidade. Por favor, reveja a nossa Política de Privacidade para obter mais informações sobre como recolhemos e utilizamos dados.
        </li>

        <li className="app-list-item">
          <strong>Conduta do Utilizador:</strong> A utilização não autorizada deste website pode dar origem a uma reivindicação por danos e/ou constituir um delito criminal.
        </li>
      </ol>
    </div>
  );
}

export default TermsOfUse;
