import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/js/Header.js';
import Home from './components/pages/Home.js';
import About from './components/js/About.js';
import Schedule from './components/pages/Schedule.js';
import Footer from './components/js/Footer.js';
import Cookies from './components/js/Cookies.js';
import CookiesPolicy from './components/pages/CookiesPolicy.js';
import Speakers from './components/js/Speakers.js';
import TermsOfUse from './components/pages/TermsOfUse.js';
import PrivacyPolicy from './components/pages/PrivacyPolicy.js';
import Exhibition from './components/pages/Exhibition.js';
import Sponsors from './components/js/Sponsors.js';
import Hotels from './components/pages/Hotels.js';
import SponsorsPage from './components/pages/SponsorPage.js';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/speakers" element={<Speakers />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/exhibition" element={<Exhibition />} />
          <Route path="/hotels" element={<Hotels />} />
          <Route path="/sponsors" element={<SponsorsPage />} />
        </Routes>
        <Sponsors />
        <Footer />
        <Cookies />
      </div>
    </Router>
  );
}

export default App;
