import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/Schedule.css';

const scheduleData = {
  days: [
    {
      id: 'tabs-1',
      date: '04 Abril, 2025',
      events: [
        { title: 'Dealing with Difficult People', speaker: 'John Smith', email: 'john@colorlib.com', time: '08:00 am - 10:00 AM', location: '59 Breanne Canyon Suite, USA', imageUrl: 'img/schedule/schedule-1.jpg' },
        { title: 'Radiology Innovations', speaker: 'Emily White', email: 'emily@colorlib.com', time: '10:30 am - 12:00 PM', location: '59 Breanne Canyon Suite, USA', imageUrl: 'img/schedule/schedule-1.jpg' },
        { title: 'AI in Medical Imaging', speaker: 'Michael Brown', email: 'michael@colorlib.com', time: '01:00 pm - 03:00 PM', location: '59 Breanne Canyon Suite, USA', imageUrl: 'img/schedule/schedule-1.jpg' },
      ],
    },
    {
      id: 'tabs-2',
      date: '05 Abril, 2025',
      events: [
        { title: 'Understanding Radiology Advances', speaker: 'Jane Doe', email: 'jane@colorlib.com', time: '10:30 am - 12:00 PM', location: '59 Breanne Canyon Suite, USA', imageUrl: 'img/schedule/schedule-2.jpg' },
        { title: 'Future of Medical Imaging', speaker: 'Alice Brown', email: 'alice@colorlib.com', time: '01:00 pm - 03:00 PM', location: '59 Breanne Canyon Suite, USA', imageUrl: 'img/schedule/schedule-2.jpg' },
        { title: 'Sustainable Practices in Radiology', speaker: 'David Green', email: 'david@colorlib.com', time: '03:30 pm - 05:00 PM', location: '59 Breanne Canyon Suite, USA', imageUrl: 'img/schedule/schedule-2.jpg' },
      ],
    },
  ],
};

function ScheduleSection() {
  const [activeTab, setActiveTab] = useState('tabs-1');

  return (
    <section className="schedule-section spad">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <h2>Programa</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="schedule-tab">
              <ul className="nav nav-tabs" role="tablist">
                {scheduleData.days.map(day => (
                  <li className="nav-item" key={day.id}>
                    <a className={`nav-link ${activeTab === day.id ? 'active' : ''}`} data-toggle="tab" href={`#${day.id}`} role="tab" onClick={() => setActiveTab(day.id)}>
                      <h5>Dia {day.id.split('-')[1]}</h5>
                      <p>{day.date}</p>
                    </a>
                  </li>
                ))}
              </ul>
              <div className="tab-content">
                {scheduleData.days.map(day => (
                  <div className={`tab-pane ${activeTab === day.id ? 'active' : ''}`} id={day.id} role="tabpanel" key={day.id}>
                    <div className="container">
                      <div className="row">
                        {day.events.map((event, index) => (
                          <div className="col-lg-4 col-md-6 mb-4" key={index}>
                            <div className="sc-item">
                              <img src={event.imageUrl} alt={event.title} />
                              <h4>{event.title}</h4>
                              <ul>
                                <li><i className="fa fa-user"></i> {event.speaker}</li>
                                <li><i className="fa fa-clock-o"></i> {event.time}</li>
                              </ul>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ScheduleSection;