import React from 'react';
import '../css/Hotels.css';

function Hotel() {
  const hotels = [
    {
      name: 'Hotel Coimbra Aeminium',
      address: 'Alameda Armando Gonçalves, 20, 3000-059 Coimbra',
      phone: '239480800',
      image: '/img/hotels/aeminium.jpg',
    },
    {
      name: 'Penedo da Saudade Suites e Hostel',
      address: 'Av. Dr. Marnoco e Sousa, 18B, 3000-271 Coimbra',
      phone: '937514507',
      discount: '10% de desconto com o código CIRC Coimbra 2024',
      image: '/img/hotels/penedo.jpg',
    },
    {
      name: 'Seminário Maior',
      address: 'Rua Vandelli, 2, 3004-547 Coimbra',
      phone: '239792340',
      rates: [
        'Quarto individual - 60€',
        'Quarto duplo - 80€',
        'Pequeno almoço incluído',
      ],
      image: '/img/hotels/seminario.jpg',
    },
    {
      name: 'Hotel Íbis Coimbra Centro',
      address: 'Edifício Topázio, Av. Emídio Navarro, 70, 3000-150 Coimbra',
      phone: '239852130',
      discount: '10% de desconto',
      image: '/img/hotels/ibis.jpg',
    },
    {
      name: 'Restaurante no Tacho',
      address: 'Rua da Morda, 20, 3000-282 Coimbra',
      phone: '911925961',
      discount: '10% em consumos iguais ou superiores a 30€ por pessoa',
      image: '/img/hotels/tacho.jpg',
    },
    {
      name: 'Spagheti Notte',
      address: 'Rua Vitorino Nemésio, 397, 3030-360 Coimbra',
      phone: '919468371',
      special: 'Welcome drink',
      image: '/img/hotels/notte.jpg',
    },
  ];

  return (
    <section className="hotel-section">
      <div className="container">
        <div className="section-title">
          <h2>Hotéis e Restaurantes</h2>
        </div>
        <div className="hotel-list">
          {hotels.map((hotel, index) => (
            <div key={index} className="hotel-card">
              <img src={hotel.image} alt={hotel.name} className="hotel-image" />
              <h3>{hotel.name}</h3>
              <p>{hotel.address}</p>
              <p>Tlf: {hotel.phone}</p>
              {hotel.discount && <p className="discount">{hotel.discount}</p>}
              {hotel.rates && hotel.rates.map((rate, i) => <p key={i}>{rate}</p>)}
              {hotel.special && <p className="highlight">{hotel.special}</p>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Hotel;