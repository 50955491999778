import React from 'react';
import '../css/About.css';

function AboutSection() {
  return (
    <section className="home-about-section spad">
      <div className="container">
        <div className="about-content">
          <div className="about-image">
            <img src={`${process.env.PUBLIC_URL}/img/cartaz_v2.jpg`} alt="Event Poster" />
          </div>
          <div className="about-text">
            <h2>
              <span className="italic-font">Bem vindo ao</span> <b className="bold-font">CIRC 2025</b>
            </h2>
            <p>
              Este evento reúne especialistas, investigadores e profissionais
              da radiologia para explorar inovações tecnológicas, avanços
              científicos e melhores práticas clínicas.
            </p>
            <p>
              Em 2025, destacamos temas como a Inteligência Artificial na radiologia e a transição
              para Hospitais Verdes, promovendo uma prática médica mais
              sustentável. Com palestras, workshops e sessões científicas, o
              congresso oferece uma oportunidade única para aprendizagem e
              networking na área da imagem médica.
            </p>
            <p>
              <b>Participe e colabore connosco em <span className="highlight-coimbra">Coimbra</span>, e faça parte do futuro da radiologia!</b>
            </p>
            <h2>
              <span className="italic-font">Eyes on</span> the future!
            </h2>
            <div className="about-buttons">
              <a href={`${process.env.PUBLIC_URL}/event-poster.pdf`} download="CIRC2025-Event-Poster.pdf" className="download-poster-btn">
                Descarregar Poster
              </a>
              <a href="/inscriptions" className="inscriptions-btn">
                Inscrição
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;