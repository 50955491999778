// src/components/js/Sponsors.js
import React from 'react';
import '../css/Sponsors.css';

const sponsors = [
  { name: 'Sponsor 1', logo: `${process.env.PUBLIC_URL}/img/partner-logo/logo-1.png` },
    { name: 'Sponsor 3', logo: `${process.env.PUBLIC_URL}/img/partner-logo/logo-3.png` },
    { name: 'Sponsor 4', logo: `${process.env.PUBLIC_URL}/img/partner-logo/logo-4.png` },
    { name: 'Sponsor 5', logo: `${process.env.PUBLIC_URL}/img/partner-logo/logo-5.png` },
 

  // Add more sponsors as needed
];

function Sponsors() {
  return (
    <section className="sponsors-section">
      <div className="container">

        <div className="sponsors-logos">
          {sponsors.map((sponsor, index) => (
            <div key={index} className="sponsor-logo">
              <img src={sponsor.logo} alt={`${sponsor.name} Logo`} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Sponsors;